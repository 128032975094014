import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaExclamationCircle } from 'react-icons/fa';
import Button from '../components/Button';

const PaymentError = () => {
 const navigate = useNavigate();

 const handleContactUs = () => {
  navigate('/contact-us'); // Adjust the route as needed
 };

 return (
  <div style={styles.container}>
   <FaExclamationCircle style={styles.icon} />
   <h2 style={styles.message}>Something Went Wrong</h2>
   <p style={styles.subText}>We’re sorry for the inconvenience.</p>
   <Button
    customClass="w-full md:w-auto"
    name="Contact Us"
    onClick={handleContactUs}
    size="medium"
    variant="primary"
    svgBefore={true}
   />
  </div>
 );
};

const styles = {
 container: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  textAlign: 'center',
  padding: '20px',
 },
 icon: {
  color: 'red',
  fontSize: '4rem',
  marginBottom: '20px',
 },
 message: {
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '10px',
 },
 subText: {
  fontSize: '1rem',
  color: '#555',
  marginBottom: '20px',
 },
};

export default PaymentError;
