import { ApolloProvider } from '@apollo/client';
import './App.css';
import Router from './components/Router';
import client from './apolloClient';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
 'pk_test_51PkjwiFGWgbMRc9BlpH4qlCLmv1S3u2q5Xv0zgev7cLRJ60Nr1rlNujL2MTXammwUs377cbdpCtom0XiNajEWXiq004UYkODTW',
);

function App() {
 return (
  <ApolloProvider client={client}>
   <Elements stripe={stripePromise}>
    <Router />;
   </Elements>
  </ApolloProvider>
 );
}

export default App;
