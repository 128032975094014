import { create } from 'zustand';

const useApiStore = create((set) => ({
 searchData: null, // initial state for the data
 municipality: null,
 activeCitation: null,
 setMunicipality: (data) => set({ municipality: data }),
 setActiveCitation: (data) => set({ activeCitation: data }),

 // Method to set the data directly
 setData: (newData) => set({ searchData: newData }),

 // Optional: Clear data method, if needed
 clearData: () => set({ searchData: null }),
}));

export default useApiStore;
