export const generateAddress = (addressObj) => {
 if (!addressObj) {
  return '';
 }
 const {
  streetNumber,
  street,
  district,
  city,
  subregion,
  region,
  postalCode,
  country,
 } = addressObj;

 // Format the address by concatenating non-empty fields
 const address = [
  streetNumber && streetNumber, // Street Number (optional)
  street && street, // Street (optional)
  district && `, ${district}`, // District (optional)
  city && `, ${city}`, // City (optional)
  subregion && `, ${subregion}`, // Subregion (optional, like a county)
  region && `, ${region}`, // Region or State (optional)
  postalCode && `, ${postalCode}`, // Postal Code (optional)
  country && `, ${country}`, // Country (optional)
 ]
  .filter(Boolean) // Remove undefined or empty fields
  .join(''); // Join all fields into a single string

 return address;
};
