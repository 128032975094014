import { gql } from '@apollo/client';

export const CONTEST_CONTACT_US = gql`
 mutation contactUs($createContactUs: CreateContactInput!) {
  contactUs(createContactUs: $createContactUs) {
   success
   message
  }
 }
`;
