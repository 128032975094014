import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import { Toaster } from 'react-hot-toast';

const MainLayout = () => {
 return (
  <>
   <Toaster position="top-right" />
   <div className=" px-[26px]  ">
    <Outlet />
   </div>
   <Footer />
  </>
 );
};

export default MainLayout;
