import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import Button from '../components/Button';

const PaymentSuccess = () => {
 const navigate = useNavigate();

 const handleBackToHome = () => {
  navigate(-1); // Adjust the route as needed
 };

 return (
  <div style={styles.container}>
   <FaCheckCircle style={styles.icon} />
   <h2 style={styles.message}>Payment Successful!</h2>
   <p style={styles.subText}>Thank you for payment.</p>
   <Button
    customClass="w-full md:w-auto"
    name="Home"
    onClick={handleBackToHome}
    size="medium"
    variant="primary"
    svgBefore={true}
   />
  </div>
 );
};

const styles = {
 container: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  textAlign: 'center',
  padding: '20px',
 },
 icon: {
  color: 'green',
  fontSize: '4rem',
  marginBottom: '20px',
 },
 message: {
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '10px',
 },
 subText: {
  fontSize: '1rem',
  color: '#555',
  marginBottom: '20px',
 },
 button: {
  padding: '10px 20px',
  fontSize: '1rem',
  backgroundColor: '#4CAF50',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
 },
};

export default PaymentSuccess;
