import React from 'react';

const OrWithLines = () => {
 return (
  <div className="flex items-center justify-center mb-4 pointer-events-none">
   <div className="flex-grow border-t border-black border-opacity-15"></div>
   <span className="mx-4 text-lg  font-semibold text-black text-opacity-30 tracking-[5px]">
    or
   </span>
   <div className="flex-grow border-t border-black border-opacity-15"></div>
  </div>
 );
};

export default OrWithLines;
