import { toast } from 'react-hot-toast';

export const Toasts = (props) => {
 const {
  variant = 'success',
  message = 'default message',
  api,
  errorMessage = 'default error message',
 } = props;

 if (api) {
  return toast.promise(api, {
   loading: 'Loading',
   success: message,
   error: errorMessage,
  });
 } else {
  return toast[variant](message);
 }
};
