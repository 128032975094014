import React, { useCallback, useState } from 'react';
import { Attach, Back, Logo, Submit } from '../assets';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { Toasts } from '../components/Toasts';
import { useMutation } from '@apollo/client';
import { CONTEST_CONTACT_US } from '../apis';
import useApiStore from '../store/store';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for email validation

const ContactUs = () => {
 const { municipality } = useApiStore();
 const navigate = useNavigate();
 const [file, setFile] = useState(null); // State for file upload
 const [value, setValue] = useState({
  firstName: '',
  lastName: '',
  email: '',
  message: '',
 }); // State for form inputs

 const [errors, setErrors] = useState({
  firstName: '',
  lastName: '',
  email: '',
  message: '',
 }); // State for error messages

 const [contactUs, { loading }] = useMutation(CONTEST_CONTACT_US);

 // Handlers for form fields
 const handleChange = (e) => {
  const { name, value } = e.target;
  setValue((prevValue) => ({
   ...prevValue,
   [name]: value,
  }));
  setErrors((prevErrors) => ({
   ...prevErrors,
   [name]: '',
  }));
 };

 const handleBlur = (e) => {
  const { name, value } = e.target;
  if (!value) {
   setErrors((prevErrors) => ({
    ...prevErrors,
    [name]: `${name} is required`,
   }));
  } else if (name === 'email' && !emailRegex.test(value)) {
   setErrors((prevErrors) => ({
    ...prevErrors,
    email: 'Invalid email format',
   }));
  }
 };

 const handleFileChange = (e) => {
  const selectedFile = e.target.files[0];
  setFile(selectedFile); // Store the selected file
 };

 const handleFileRemove = useCallback(() => {
  setFile(null); // Remove the file
 }, []);

 const handleContactFormSubmission = useCallback(async () => {
  // Check for validation before submission
  const newErrors = {};
  if (!value?.firstName) newErrors.firstName = 'First Name is required';
  if (!value?.lastName) newErrors.lastName = 'Last Name is required';
  if (!value?.email) {
   newErrors.email = 'Email is required';
  } else if (!emailRegex.test(value?.email)) {
   newErrors.email = 'Invalid email format';
  }
  if (!value?.message) newErrors.message = 'Message is required';

  if (Object.keys(newErrors).length > 0) {
   setErrors(newErrors);
   return;
  }

  try {
   await contactUs({
    variables: {
     createContactUs: {
      fullName: value?.firstName + ' ' + value?.lastName,
      email: value?.email,
      file: file,
      message: value?.message,
     },
    }, // Pass the value state along with the file
   });
   Toasts({ variant: 'success', message: 'Email sent successfully' });
   setValue({ email: '', firstName: '', lastName: '', message: '' });
   setFile(null);
  } catch (error) {
   console.error(error);
  }
 }, [contactUs, value, file]);

 return (
  <>
   <div className="py-[30px] ">
    <div className="flex justify-center items-start pb-8">
     {municipality?.logo && (
      <img
       className="align-center w-[156px] h-[156px]"
       src={municipality?.logo}
       alt="Municipality-logo"
      />
     )}
    </div>
    <div className="flex justify-center items-center w-full max-h-[409px] overflow-y-scroll">
     <div className="flex-1 flex-col justify-start max-w-[323px] md:max-w-[429px]">
      <div className="border-b w-full"></div>
      <div className="flex flex-col justify-start items-start text-start gap-3 w-full pb-9 md:pb-[18px] pt-8 overflow-y-scroll ">
       <p className="text-balance text-lg font-medium">Contact Us</p>
       <p className="text-xs text-black text-opacity-70">
        We try to respond to everyone as quickly as possible. Expect to receive
        a reply from us within 1 business day.
       </p>
      </div>
      <div className="flex justify-between items-start gap-3">
       <div className="w-full">
        <input
         type="text"
         name="firstName"
         value={value?.firstName}
         onChange={handleChange}
         onBlur={handleBlur}
         placeholder="First Name"
         className="font-normal bg-white placeholder:font-normal placeholder:text-sm border w-full hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10"
        />
        {errors.firstName && (
         <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>
        )}
       </div>
       <div className="w-full">
        <input
         type="text"
         name="lastName"
         value={value?.lastName}
         onChange={handleChange}
         onBlur={handleBlur}
         placeholder="Last Name"
         className="font-normal bg-white placeholder:font-normal placeholder:text-sm border w-full hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10"
        />
        {errors.lastName && (
         <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>
        )}
       </div>
      </div>
      <div>
       <input
        type="email"
        name="email"
        value={value?.email}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Email"
        className="font-normal bg-white placeholder:font-normal placeholder:text-sm border w-full hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10 mt-4"
       />
       {errors.email && (
        <p className="text-red-500 text-xs mt-1">{errors.email}</p>
       )}
      </div>

      <textarea
       id="message"
       name="message"
       rows="4"
       value={value?.message}
       onChange={handleChange}
       onBlur={handleBlur}
       placeholder="Your message"
       className="border border-black border-opacity-30 rounded-[5px] max-h-[234px] md:max-h-[101px] max-w-[323px] md:max-w-[429px] mt-4 w-full"
      />
      {errors.message && (
       <p className="text-red-500 text-xs mt-1">{errors.message}</p>
      )}

      {/* File Upload Section */}
      <div className="flex flex-col gap-4 w-full mt-4 pb-3">
       {/* Show file name with "x" icon if a file is uploaded */}
       {file && (
        <div className="flex items-center justify-between border p-2 rounded">
         <span className="text-sm">{file.name}</span>
         <button
          type="button"
          className="text-red-500 text-lg"
          onClick={handleFileRemove}
         >
          &times;
         </button>
        </div>
       )}

       {/* File upload input */}
      </div>
      <div>
       <div className="flex justify-between items-center w-full gap-4">
        <Button
         name={'Submit'}
         onClick={handleContactFormSubmission}
         size="small"
         fullWidth={true}
         variant="primary"
         svg={<Submit />}
         svgBefore={true}
         customClass=" flex-1 !tracking-[2px] w-full "
        />

        <input
         type="file"
         id="file-upload"
         style={{ display: 'none' }}
         onChange={handleFileChange}
        />

        {/* Trigger file input click via button */}
        <Button
         name={'Attach File'}
         size="small"
         fullWidth={true}
         variant="secondary"
         svg={<Attach />}
         svgBefore={true}
         customClass={loading ? '' : 'flex-1 !tracking-[2px] w-full'}
         onClick={() => document.getElementById('file-upload').click()} // Trigger file input click
        />
       </div>
      </div>
     </div>
    </div>
   </div>
   <div className="absolute top-[37px] left-[30px] cursor-pointer">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(-1)}
    >
     <Back />
     <p>Back</p>
    </div>
   </div>
  </>
 );
};

export default ContactUs;
