import React, { useEffect, useState } from 'react';
import {
 CardElement,
 useStripe,
 useElements,
 PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { CardDark } from '../assets';
import Button from './Button';
import useApiStore from '../store/store';
import { STATES } from '../constants';
import { useNavigate } from 'react-router-dom';

const StripePaymentForm = ({ clientSecret }) => {
 const [loading, setLoading] = useState(false);
 const stripe = useStripe();
 const elements = useElements();
 const { activeCitation } = useApiStore();
 const navigate = useNavigate();

 // Validation schema using Yup
 const validationSchema = Yup.object().shape({
  //   country: Yup.string().required('Country is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  email: Yup.string().email().required('Email is required'),
 });

 const handleSubmit = async (values, { setSubmitting }) => {
  setLoading(true);
  try {
   if (!stripe || !elements) return;

   const cardElement = elements.getElement(CardElement);

   const { error, paymentIntent } = await stripe.confirmCardPayment(
    clientSecret,
    {
     payment_method: {
      card: cardElement,
      billing_details: {
       address: {
        city: values.city,
        //    country: values.country,
        state: values.state,
       },
       name: `${values.firstName} ${values.lastName}`,
       email: values?.email,
      },
      metadata: {
       email: values.email,
       firstName: values.firstName,
       lastName: values.lastName,
       city: values.city,
       state: values.state,
       //    country: values.country,
      },
     },
    },
   );
   navigate('/payment-success');
   setSubmitting(false);

   if (error) {
    console.error('Payment failed:', error);
   } else {
    console.log('Payment successful:', paymentIntent);
   }
  } catch (error) {
   console.log('EERRR', error);
  } finally {
   setLoading(false);
  }
 };
 const [paymentRequest, setPaymentRequest] = useState(null);
 const [canMakePayment, setCanMakePayment] = useState(false);
 console.log('canMakePayment', canMakePayment);
 console.log('paymentRequest', paymentRequest);

 useEffect(() => {
  const pr = stripe.paymentRequest({
   country: 'US',
   currency: 'usd',
   total: {
    label: 'Citation',
    amount: activeCitation?.fineAmount * 100, // Amount in cents (5000 = $50.00)
   },
   requestPayerName: true,
   requestPayerEmail: true,
  });
  pr.canMakePayment().then((result) => {
   setCanMakePayment(!!result);
  });
  setPaymentRequest(pr);
 }, [stripe, activeCitation]);
 return (
  <div className="max-w-[429px]">
   <div className="flex  items-center gap-2 ">
    <CardDark /> <p className="text-lg font-medium">Pay Citation</p>
   </div>
   <p className="text-xs font-normal opacity-70 py-3">
    Please enter your debit or credit card credentials to pay your citation.
   </p>
   <Formik
    initialValues={{
     //  country: '',
     firstName: '',
     lastName: '',
     city: '',
     state: '',
     email: '',
    }}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
   >
    {({ isSubmitting, errors }) => (
     <Form>
      {/* <div>
       <Field
        as="select"
        name="country"
        className={`w-full border ${
         errors?.country ? 'border-gray-300' : 'border-red-500'
        } rounded-lg px-4 py-2 text-left flex justify-between items-center`}
       >
        <option value="United States">United States</option>
        <option value="Canada">Canada</option>
       </Field>
       

       <div className="h-5">
        <ErrorMessage
         name="country"
         component="div"
         className="text-red-500 text-sm "
        />
       </div>
      </div> */}
      <div className="w-full">
       <Field
        type="text"
        name="email"
        className="font-normal bg-white placeholder:font-normal placeholder:text-sm border border-gray-300 w-full hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10 "
        placeholder="Email"
       />
       <div className="h-5">
        <ErrorMessage
         name="email"
         component="div"
         className="text-red-500 text-sm"
        />
       </div>
      </div>
      <div className="flex justify-between items-start gap-2 w-full">
       <div className="w-full">
        <Field
         type="text"
         name="firstName"
         className="font-normal bg-white placeholder:font-normal placeholder:text-sm border border-gray-300 w-full hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10 "
         placeholder="First Name"
        />
        <div className="h-5">
         <ErrorMessage
          name="firstName"
          component="div"
          className="text-red-500 text-sm"
         />
        </div>
       </div>

       <div className="w-full">
        <Field
         type="text"
         name="lastName"
         className="font-normal bg-white placeholder:font-normal placeholder:text-sm border border-gray-300 w-full hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10 "
         placeholder="Last Name"
        />
        <div className="h-5">
         <ErrorMessage
          name="lastName"
          component="div"
          className="text-red-500 text-sm"
         />
        </div>
       </div>
      </div>

      <div className="flex justify-between items-center gap-2">
       <div className="flex-1">
        <Field
         type="text"
         name="city"
         placeholder="City"
         className="font-normal bg-white placeholder:font-normal placeholder:text-sm border border-gray-300 w-full hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10 "
        />
        <div className="h-5">
         <ErrorMessage
          name="city"
          component="div"
          className="text-red-500 text-sm"
         />
        </div>
       </div>
       <div className="flex flex-col flex-1">
        <Field
         as="select"
         name="state"
         className="w-full border border-gray-300 rounded-lg px-4 py-2 text-left flex justify-between items-center"
        >
         <option value="">State</option>
         {STATES.map(({ value, label }) => (
          <option key={value} value={value}>
           {label}
          </option>
         ))}
        </Field>
        <div className="h-5">
         <ErrorMessage
          name="state"
          component="div"
          className="text-red-500 text-sm"
         />
        </div>
       </div>
      </div>
      <div className="border border-gray-300 px-2 rounded-md py-3 flex-1">
       <CardElement />
      </div>
      {paymentRequest && canMakePayment && (
       <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
      <div className="pt-5">
       <Button
        loading={loading}
        name={'Pay '}
        type="submit"
        customClass=" !tracking-[2px] md:mr-2 w-full !py-[-12] "
        onClick={() => {}}
        size="extraSmall"
        variant="secondary"
        disabled={!stripe || isSubmitting}
        svg={<CardDark />}
        svgBefore={true}
       />
      </div>
     </Form>
    )}
   </Formik>
  </div>
 );
};

export default StripePaymentForm;
