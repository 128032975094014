import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

// Create the upload link
const uploadLink = createUploadLink({
 uri: 'http://ec2-18-209-7-106.compute-1.amazonaws.com:3000/graphql',
 //  uri: 'https://api.enforceapp.com/graphql',
});

// Set context middleware to add necessary headers
const authLink = setContext((_, { headers }) => {
 return {
  headers: {
   ...headers,
   'x-apollo-operation-name': 'your-operation-name', // Replace with actual operation name or value
   'apollo-require-preflight': 'true', // Ensures that the preflight check is done
   'Content-Type': 'application/json', // Ensure this is not one of the restricted types
  },
 };
});

// Combine the links
const link = ApolloLink.from([authLink, uploadLink]);

const client = new ApolloClient({
 link,
 cache: new InMemoryCache(),
});

export default client;
