import React from 'react';
import { Back, Image } from '../assets';
import DetailsComponent from '../components/DetailsComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import StripePaymentForm from '../components/StripePaymentForm';
import useApiStore from '../store/store';
import { generateAddress } from '../util/helperFunctions';

const PayCitation = () => {
 const location = useLocation();
 const navigate = useNavigate();
 const { municipality, activeCitation } = useApiStore();

 const clientSecret = location?.state?.intent;
 return (
  <>
   <div className="py-[30px]">
    <div className="flex justify-center items-center">
     {municipality?.logo && (
      <img
       className="align-center w-[156px] h-[156px]"
       src={municipality?.logo}
       alt="Municipality-logo"
      />
     )}
    </div>
    <div className="flex justify-center items-center w-full">
     <div className=" flex-1 flex-col justify-start max-w-[373px] md:max-w-[429px] ">
      <div className="flex flex-col justify-start items-start gap-[18px]">
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Owner:</p>
        <p className="text-black text-opacity-70">John Hancock</p>
       </div>
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Owner’s Address:</p>
        <p className="text-black text-opacity-70">
         04914 Murray Lights Apt. 037, Fort Collins, NC, 21085
        </p>
       </div>
       <div className="border-b w-full"></div>
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Vehicle:</p>
        <p className="text-black text-opacity-70">
         {`${activeCitation?.color || ''} ${activeCitation?.make || ''} ${
          activeCitation?.modal || ''
         }`}
        </p>
       </div>
       <DetailsComponent
        titleLeft="License Plate: "
        descriptionLeft="43BCI8"
        titleRight="State:"
        descriptionRight="NC"
       />
       <DetailsComponent
        titleLeft="Status:  "
        descriptionLeft="Active"
        titleRight="Reg. Expiring:"
        descriptionRight="09/21/2024"
       />
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Registration Address:</p>
        <p className="text-black text-opacity-70">
         {activeCitation?.location?.address ||
          generateAddress(activeCitation?.locationResult)}
        </p>
       </div>
       <div className="border-b w-full"></div>
       <DetailsComponent
        titleLeft="Date/Time: "
        descriptionLeft={activeCitation?.date}
        titleRight="Type of Violation:"
        descriptionRight={activeCitation?.violation?.title}
       />
       <DetailsComponent
        titleLeft="Parking in bike lane "
        descriptionLeft="Not Paid"
        titleRight="Amount Owed:"
        descriptionRight={activeCitation?.fineAmount}
       />
       <DetailsComponent
        titleLeft="Citation #:"
        descriptionLeft={activeCitation?.citationNumber}
        titleRight="Evidence:"
        descriptionRight={
         activeCitation?.photo ? (
          <a
           href={activeCitation?.photo}
           target="_blank"
           rel="noopener noreferrer"
          >
           <div className="flex justify-start items-center gap-2">
            <Image />
            <p className="text-xs text-black text-opacity-70">evidence.png</p>
           </div>
          </a>
         ) : (
          '---'
         )
        }
       />
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Address of Violation:</p>
        <p className="text-black text-opacity-70">
         {activeCitation?.location?.address ||
          generateAddress(activeCitation?.locationResult)}
        </p>
       </div>
       <div className="border-b w-full"></div>
       <div className="flex flex-col justify-start items-start gap-2 rounded-md bg-black bg-opacity-5 w-full p-4">
        <div className="flex justify-between items-start w-full">
         <p className="text-black text-opacity-70 text-xs">Citation Payment</p>
         <p className="text-black text-opacity-70 text-xs">
          ${activeCitation?.fineAmount}
         </p>
        </div>
        <div className="flex justify-between items-start w-full">
         <p className="text-black text-opacity-70 text-xs">Service Fee</p>
         <p className="text-black text-opacity-70 text-xs">$0.00</p>
        </div>
        <div className="flex justify-between items-start w-full">
         <p className="text-black text-opacity-70 font-bold text-xs">Total</p>
         <p className="text-black text-opacity-70 font-bold text-xs">
          ${activeCitation?.fineAmount}
         </p>
        </div>
       </div>
       <div className="border-b w-full">
        <div className="absolute top-[37px] left-[30px] cursor-pointer"></div>
        {/* stripe checkput fields will come below */}
        {/* <p className="text-black text-opacity-70 text-base font-bold">
        stripe fields will come here as it is{' '}
       </p> */}
       </div>
      </div>
      <StripePaymentForm clientSecret={clientSecret} />
     </div>
    </div>
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(-1)}
    >
     <Back />
     <p>Back</p>
    </div>
   </div>
  </>
 );
};

export default PayCitation;
